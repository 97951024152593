import server from '@/api';
import api from '@/api/config';
// console.log('测试')
// server.login.loginIn()

//是否可以添加子账户
export function isAuthority(param) {
  return server.users.isAuthority(param);
}
//添加子账户
export function registerBypass(param) {
  return server.users.registerBypass(param);
}
//获取收款账号
export function collectPayInfoList(params) {
  return server.users.collectPayInfoList({params});
}
//获取收款账号
export function updateCollectPayId(param) {
  return server.users.updateCollectPayId(param);
}
//获取权限
export function myAuthority(param) {
  return server.users.myAuthority(param);
}

//获取用户中心订单信息
export function static4OrderAmt(params) {
  return server.users.static4OrderAmt({params});
}

//收付款账号修改
export function updatePayInfo(params) {
  return server.users.updatePayInfo(params);
}

//收付款账号新增
export function insertPayInfo(params) {
  return server.users.insertPayInfo(params);
}

//获取用户中心买家任务信息
export function mybuyderOrderList(params) {
  return server.users.mybuyderOrderList(params);
}

//获取用户中心卖家任务信息
export function mysellerOrderList(params) {
  return server.users.mysellerOrderList(params);
}

//站内消息
export function getMessgeList(params) {
  return server.users.getMessgeList({params});
}
//更改状态标识
export function updateReadStatus(params) {
  return server.users.updateReadStatus(params);
}

//校验登录名
export function verifyLoginName(param) {
  return server.login.verifyLoginName(param);
}

//校验手机号码
export function selectCountByTel(param) {
  return server.login.selectCountByTel(param);
}
//3.引入 src/api 定义的接口   企业认证
//post请求 只需要传入 param get请求需要 {params}
export function updateTClientInfoOne(param) {
  return server.clientInfo.updateTClientInfoOne(param);
}
//法人认证
export function updateTClientInfoTwo(param) {
  return server.clientInfo.updateTClientInfoTwo(param);
}

//查询子账户
export function selectSubInfo(param) {
  return server.users.selectSubInfo(param);
}

//企业信息
export function findByClientInfoId(params) {
  return server.clientInfo.findByClientInfoId({params});
}

//用户资料
export function selectByPrimaryKey(params) {
  return server.clientInfo.selectByPrimaryKey({params});
}

//审核未通过重新申请
export function updateApprvStatus(params) {
  return server.clientInfo.updateApprvStatus({params});
}

// 新增竞买
export function addProProduct(params) {
  return server.proProduct.addProProduct(params);
}

// 删除竞买
export function deleteProProduct(params) {
  return server.proProduct.deleteProProduct(params);
}

// 获取产品分类
export function categoryAll(categoryId = null) {
  return server.category.categoryAll(categoryId);
}

// 竞买列表
export function proProductList(params) {
  return server.proProduct.proProductList(params);
}
// 竞买审核
export function auditOrFaudit(params) {
  return server.proProduct.auditOrFaudit({params});
}

// 竞买列表
export function getMyPartProduct(params) {
  return server.proProduct.getMyPartProduct(params);
}

// 获取竞买详情
export function getProducDetail(params) {
  return server.proProduct.getProducDetail({params});
}

// 获取报价详情
export function getPriceList(params) {
  return server.proProduct.getPriceList({params});
}

// 确认采购
export function confirmPurchase(params) {
  return server.proProduct.confirmPurchase({params});
}

// 修改竞买
export function updateProProduct(params) {
  return server.proProduct.updateProProduct(params);
}

// 撤回竞买
export function withdrawProduct(params) {
  return server.proProduct.withdrawProduct({params});
}

//启用/禁用子账户
export function disableByLoginName(param) {
  return server.users.disableByLoginName(param);
}

// 修改子账户信息 updateSubInfoByLoginName
export function updateSubInfoByLoginName(param) {
  return server.users.updateSubInfoByLoginName(param);
}

// 得到企业商品集合
export function getClientGoods(param) {
  return server.goodsInfo.getClientGoods(param);
}
//添加商品
export function addGoods(param) {
  return server.goodsInfo.publishGoods(param);
}
//显示企业商品详情
export function goodsDetail(params) {
  return server.goodsInfo.goodsDetail({params});
}
//修改企业商品信息
export function modifyGoods(param) {
  return server.goodsInfo.modifyGoods(param);
}
//修改企业商品信息
export function delGoods(params) {
  return server.goodsInfo.delGoods({params});
}
//商品审核
export function goodCheck(param) {
  return server.goodsInfo.goodCheck(param);
}

//南海灵通铝锭最新价格获取
export function newestLvdingPrice() {
  return server.lvDingPrice.newestLvdingPrice();
}

//文件上传
export const uploadPath = api.uploadPath;

export const uploadPaths = api.uploadPaths;

export function createStorage(param) {
  return server.storage.createStorage(param);
}

// 根据id物理删除
export function deleteById(param) {
  return server.users.deleteById(param);
}

// 报价确认订单信息
export function getProPriceOrderInfo(params) {
  return server.proProduct.getProPriceOrderInfo({params});
}

// 竞买、竞卖订单详情
export function detailOrder(params) {
  return server.proProduct.detailOrder({params});
}

// 报价确认订单信息
export function createReqOrder(params) {
  return server.proProduct.createReqOrder(params);
}

// 买家竞买、竞卖订单列表
export function buyderOrderList(params) {
  return server.proProduct.buyderOrderList(params);
}

// 賣家竞买、竞卖订单列表
export function sellderOrderList(params) {
  return server.proProduct.sellderOrderList(params);
}

// 申请店铺
export function applyBrand(params) {
  return server.brand.applyBrand(params);
} // 根据企业id查询店铺信息
export function selectDtsBrandByCommpany(params) {
  return server.brand.selectDtsBrandByCommpany(params);
}

// 添加竞卖
export function addsAutionInfo(params) {
  return server.autionInfo.addsAutionInfo(params);
}
//获取验证码
export function getCode(params) {
  return server.login.getCode(params);
}
//查询主账号所拥有权限
export function selectPermiss(params) {
  return server.login.selectPermiss(params);
}
//修改角色，权限
export function updatePermission(params) {
  return server.login.updatePermission(params);
}
//校验验证码
export function verifyCode(params) {
  return server.login.verifyCode(params);
}
// 修改密码
export function updatePwd(params) {
  return server.login.updatePwd(params);
}
// 绑定手机号码
export function updateTel(params) {
  return server.login.updateTel(params);
}
// 用户订单列表
export function listOrder(params) {
  return server.order.listOrder({params});
}
// 供应商订单列表
export function sellderListOrder(params) {
  return server.order.sellderListOrder({params});
}
// 用户取消订单
export function cancelOrder(params) {
  return server.order.cancelOrder({params});
}
//供应商确认取消
export function confirmCancelOrder(params) {
  return server.order.confirmCancelOrder({params});
}

//订单状态修改
export function changeOrderStatus(param) {
  return server.order.changeOrderStatus(param);
}

//审核通过
export function getPassAudit(params) {
  return server.order.passAudit(params);
}

//审核不通过
export function getRejectAudit(params) {
  return server.order.rejectAudit(params);
}

//审核记录
export function getAuditProcessList(params) {
  return server.order.getAuditProcessList(params);
}

//获取分期付款/分期交货信息
export function getInstallmentData(params) {
  return server.order.getInstallmentData(params);
}

//签收凭证信息回显
export function getQSEchoInformation(params) {
  return server.order.getQSEchoInformation(params);
}

//发票信息回显
export function getKPEchoInformation(params) {
  return server.order.getKPEchoInformation(params);
}

//支付凭证信息回显
export function getZFEchoInformation(params) {
  return server.order.getZFEchoInformation(params);
}

//发货信息回显
export function getFHEchoInformation(params) {
  return server.order.getFHEchoInformation(params);
}

//获取竞标的商品信息
export function getTBidProductListData(params) {
  return server.order.getTBidProductListData(params);
}

//获取订单操作记录
export function getOrderRecordList(params) {
  return server.order.getOrderRecordList(params);
}

// 我的参与竞卖记录
export function myAutionDealPage(params) {
  return server.autionDeal.myAutionDealPage({params});
}
// 我发布的竞卖
export function myAutionInfolist(params) {
  return server.autionInfo.myAutionInfolist({params});
}

//竞卖详情
export function autionInfoDteail(params) {
  return server.autionInfo.autionInfoDteail(params);
}

// 获取操作记录
export function getOperatingRecord(params) {
  return server.order.getOperatingRecord({params});
}

// 订单交易操作
export function oprOrderStatus(params) {
  return server.order.oprOrderStatus(params);
}
// 我的竞卖列表查看详情
export function myAutionInfoDetail(params) {
  return server.autionInfo.myAutionInfoDetail({params});
}

//编辑我的竞卖信息
export function myAutionInfoUpdate(params) {
  return server.autionInfo.myAutionInfoUpdate(params);
}
//竞卖的撤回
export function myAutionInfoWithdraw(params) {
  return server.autionInfo.myAutionInfoWithdraw(params);
}
//审核
export function checkbid(params) {
  return server.autionInfo.checkbid(params);
}

//编辑我的拍卖信息回显
export function autionInfoById(params) {
  return server.autionInfo.autionInfoById({params});
}
//显示用户收货地址
export function userAddressList(params) {
  return server.address.userAddressList({params});
}
//删除用户收货地址
export function deleteAddress(param) {
  return server.address.deleteAddress(param);
}
//查询单个收货地址
export function detailAddress(params) {
  return server.address.detailAddress({params});
}
//增加或修改收货地址
export function saveAddress(param) {
  return server.address.saveAddress(param);
}
//查询行政区域
export function regionList(params) {
  return server.region.regionList({params});
}
//查询行政区域
export function regionProvinceAndCity() {
  return server.region.regionProvinceAndCity();
}
//修改用户头像或者用户名
export function updateUserNameOrPhotoUrl(params) {
  return server.users.updateUserNameOrPhotoUrl({params});
}

// 获取我的合同
export function getMyContranct(params) {
  return server.order.getMyContranct({params});
}

// 获取合同详情
export function getContranctDetail(params) {
  return server.order.getContranctDetail(params);
}

// 签署合同
export function signContranct(params) {
  return server.order.signContranct(params);
}

// 获取合同哈希值
export function getContractHash(params) {
  return server.order.getContractHash(params);
}

// 查询我的证书
export function tcredentialsigninfo(params) {
  return server.certificate.tcredentialsigninfo(params);
}

// 证书使用记录
export function tcredentialuseinfo(params) {
  return server.certificate.tcredentialuseinfo(params);
}

// 获取收付款账号详情
export function getCollectPayInfo(params) {
  return server.users.getCollectPayInfo({params});
}

export function addCollectPayInfo(params) {
  return server.users.addCollectPayInfo(params);
}

// 合作银行列表
export function bankInfoAll(params) {
  return server.bankInfo.bankInfoAll({params});
}

//认证企业 第三步
export function updateTClientInfoThree(param) {
  return server.clientInfo.updateTClientInfoThree(param);
}

// 新增竞标
export function addCompetitive(params) {
  return server.proProduct.addCompetitive(params);
}

// 获取我的竞标
export function getMyCompetitive(params) {
  return server.proProduct.getMyCompetitive(params);
}

// 获取参与的竞标
export function getParticipateCompetitive(params) {
  return server.proProduct.getParticipateCompetitive(params);
}

// 获取所有合同
// export function getAllContract (params) {
//   return server.proProduct.getAllContract({params});
// }

export function getAUContract(params) {
  return server.proProduct.getAUContract({params});
}

export function getREContract(params) {
  return server.proProduct.getREContract({params});
}

export function getBIContract(params) {
  return server.proProduct.getBIContract({params});
}
export function getContractInfo(params) {
  return server.proProduct.getContractInfo({params});
}

// 获取买家竞标列表
export function getBuyderBidOrderList(params) {
  return server.order.getBuyderBidOrderList(params);
}

// 获取供应商竞标列表
export function getSupplierBidOrderList(params) {
  return server.order.getSupplierBidOrderList(params);
}

// 获取竞标详情
export function getBidOrderDetail(params) {
  return server.order.getBidOrderDetail({params});
}

//获取竞标详情
export function getSelectBiddingDetails(params) {
  return server.tender.getSelectBiddingDetails({params});
}

// 修改竞标
export function updateCompetitive(params) {
  return server.proProduct.updateCompetitive(params);
}

// 撤回竞标
export function withdrawCompetitive(params) {
  return server.proProduct.withdrawCompetitive({params});
}
//竞标发布的商品excel上传解析
export const importExcel = api.tender.importExcel;

//南海灵通铝锭最新价格获取
export function getInvoiceInfo(params) {
  return server.clientInfo.getInvoiceInfo({params});
}
//识别汇票
export function identifyNote(params) {
  return server.storage.identifyNote({params});
}

//增值税汇票
export function vatInvoiceDistinguish(params) {
  return server.storage.vatInvoiceDistinguish({params});
}

// 获取企业设备合同列表
export function getEquipmentList(params) {
  return server.equipment.getEquipmentList(params);
}

// 添加企业设备
export function AddEquipment(params) {
  return server.equipment.addEquipment(params);
}

// 添加企业设备合同
export function upDateEquipment(params) {
  return server.equipment.updateEquipment(params);
}

// 查看企业设备合同详情
export function checkEquipment(params) {
  return server.equipment.checkEquipment({params});
}

// 可关联设备
export function associateEquipment(params) {
  return server.equipment.associateEquipment(params);
}

// 已关联设备（分页）
export function getAllTrackerByUserId(params) {
  return server.equipment.getAllTrackerByUserId(params);
}

// 已关联设备（全部）
export function getTrackListByContractId(params) {
  return server.equipment.getTrackListByContractId(params);
}

// 关联设备
export function batchInsertContractLink(params) {
  return server.equipment.batchInsertContractLink(params);
}

// 删除联设备合同
export function delById(params) {
  return server.equipment.delById({params});
}


// 获取所有该子账号的角色和角色权限
export function permissionByAllRole(params) {
  return server.account_management.permissionByAllRole(params);
}

// 获取所有该子账号的角色和角色权限
export function permissionByParent(params) {
  return server.account_management.permissionByParent(params);
}
// 竞卖审核历史记录
export function AUAuditList(params) {
  return server.autionInfo.AUAuditList(params);
}
// 竞标审核历史记录
export function BIAuditList(params) {
  return server.tender.BIAuditList(params);
}
// 竞买审核历史记录
export function REAuditList(params) {
  return server.tender.REAuditList(params);
}
